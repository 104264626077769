/*******
 * Layout
 **/

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  overflow-x: auto;
}

body {
  margin: 0;
  // background: #E1E1E1;
  text-align: center;
  background-image: url(images/background-option-b.png);
  background-size: cover;
  background-position: center;
}

.viewport {
  position: relative;
  max-width: 1090px;
  width: 100%;
  display: inline-block;
  max-height: 100%;
  height: 100%;

  // @media (min-width: 768px) {
  // 	margin: 0 60px;
  // }

  & > * {
    text-align: left;
  }

  main & {
    width: 100%;
    max-width: 1090px;

    display: flex;
    flex-direction: column;
    margin: auto;
  }
}

.logo {
  //background-image: url(images/mediasia-interactive-logo.png);
  // background-image: url(images/slingshot-logo.png);
  background-image: url(images/logo-white.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

header {
  // position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: block;
  height: 84px;
  overflow: hidden;
  // background: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  // position: fixed;
  // z-index: 1;

  .logo {
    display: none;
    @media (min-width: 375px) {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      height: 47px;
      width: 95px;
    }
  }

  .logo-vinyl {
    position: absolute;
    top: 0px;
    left: 0;
    height: 84px;
    width: 150px;
    font-size: 84px;
    line-height: 105%;
    text-decoration: none;
    color: $grey;
    svg path {
      fill: black;
    }
  }

  nav {
    ul {
      margin: 0;
      padding: 0;
      float: right;
      list-style: none;
      display: flex;
      gap: 21px;

      li {
        margin: 0;
        padding: 0;
        float: left;
        a {
          display: block;
          color: white;
          text-decoration: none;
          padding: 30px 0;
          margin: 0 20px;
          text-transform: uppercase;
          font-family: "Jost";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          position: relative;
          transition: color 0.2s;

          &:after {
            content: "";
            height: 4px;
            background: transparent;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            transition: background 0.2s;
          }

          &.active,
          &:hover {
            // color: $grey;

            &:after {
              background: white;
            }
          }
        }

        .logout {
          height: 40px;
          border: 1px solid white;
          background-color: transparent;
          padding: 10px 40px;
          font-weight: 400;
          font-size: 16px;
          text-transform: uppercase;
          margin-top: 21px;
        }
      }
    }
  }
}

main {
  // position: absolute;
  // top: 84px;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // overflow: auto;
  // padding-top: 84px;

  .fullscreen & {
    top: 0;
  }

  .head {
    // background: #1A1B27;
    background-color: rgba(0, 0, 0, 0.7);
    height: 252px;
    margin-bottom: -75px;
    position: relative;

    h1 {
      font-size: 64px;
      text-transform: uppercase;

      i {
        font-size: 22px;
        margin-left: 8px;
        cursor: pointer;

        &:before {
          color: $mediumGrey;
          transition: color 0.2s;
        }

        &:hover {
          &:before {
            color: #fff;
          }
        }
      }

      .playlist-cover {
        width: 80px;
        height: 80px;
        background: #ddd;
        border: 1px solid #fff;
        float: left;
        margin-right: 20px;
        transition: opacity 0.25s;
        cursor: pointer;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .viewport {
      padding-top: 20px;
    }
  }

  .content {
    height: calc(100vh - 177px - 84px);
    overflow: hidden;
  }
}

.actions {
  &.under-content {
    margin-top: 48px;
  }

  .buttons {
    display: flex;
  }

  .deselect {
    margin: auto 0;
    margin-left: 0;
    margin-right: 8px;
    height: 100%;
    position: relative;
    color: #ffffff;
    text-transform: uppercase;
    cursor: pointer;
  }
}

// Small padding
.ps {
  padding: 4px 0;
}

.breadcrumb {
  position: absolute;
  top: 20px;
  left: 0;
  color: $mediumGrey;
  text-decoration: none;

  i {
    font-size: 60px;
    position: relative;
    top: 2px;
    &::before {
      color: #fff;
    }
  }
}

.tabs {
  position: absolute;
  right: 0;
  top: 62px;
  display: flex;
  gap: 25px;

  .tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    // margin-left: 4px;
    // padding: 4px 14px;
    // background: transparent;
    // border-radius: 40px;
    // color: #535466;
    text-decoration: none;
    // display: inline-block;

    .dot {
      transition: background 0.25s, color 0.25s;
      border: 1px solid white;
      height: 15px;
      width: 15px;
      border-radius: 7.5px;
    }

    .title {
      text-transform: uppercase;
      color: white;
      font-size: 16px;
      font-weight: 500;
      text-decoration: none;
    }

    &:hover {
      .dot {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    &.active {
      .dot {
        background-color: white;
      }
      // color: #fff;
      // background: #2F3042;
    }
  }
}

.filters {
  margin-bottom: 12px;
  float: left;
  display: flex;
  align-items: center;
  gap: 4px;

  & > div {
    float: left;
    margin-right: 12px;
    position: relative;
    z-index: 5;

    &:before {
      position: absolute;
      top: 6px;
      left: 10px;
      font-size: 16px;
    }

    &.select {
      & > div {
        height: 29px;

        .css-1hwfws3 {
          width: 145px;
        }

        & > .css-yk16xz-control,
        & > .css-1pahdxg-control {
          min-height: 29px;
          border-radius: 42px;

          .css-g1d714-ValueContainer {
            width: 145px;
          }

          .css-tlfecz-indicatorContainer,
          .css-1gtu0rj-indicatorContainer {
            padding: 4px;
          }
        }
      }
    }
  }
}

.buttons {
  margin-bottom: 12px;
  float: right;
  & > * {
    float: left;
    margin-left: 8px;
  }
}

.fields {
  .field {
    form & {
      width: 25%;
    }
    form.campaign-form & {
      width: auto;
    }
    float: left;
    padding: 5px 16px 15px 0;
    label {
      min-height: 14px;
      opacity: 0.6;
    }
    input,
    select {
      width: 100%;
      height: 30px;
    }
  }
}

.table-wrapper {
  flex: 1;
  overflow: auto;
}

.block,
.table-wrapper {
  background: #ffffff;
  border: 1px solid #e2e4e9;
  box-shadow: 0px 4px 16px rgba(26, 27, 39, 0.12);
  border-radius: 4px;
}

.block {
  padding: 40px;
  position: relative;
  border-radius: 40px;

  .loader {
    position: absolute;
  }

  &.above {
    z-index: 10;
  }

  h2 {
    margin-bottom: 14px;
  }

  .header-window {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h2 {
      color: #000;
      text-transform: uppercase;
      margin: 0;
      font-weight: 700;
      font-size: 26px;
    }

    button {
      background-color: #000;
      padding: 10px 20px;

      &.delete-button {
        background-color: #fff;
        border: 1px solid $red;
        color: $red;
      }
    }
    .disabled-button {
      background-color: gray;
      color: white;
      border: none;
      padding: 10px 20px;
      cursor: not-allowed;
      opacity: 0.6;
    }

    .disabled-button:disabled {
      pointer-events: none;
    }
  }

  .section {
    margin: 20px 0px;
    padding-top: 20px;
    border-top: 1px solid #dadada;

    span {
      text-transform: uppercase;
      font-size: 16px;
      color: #dadada;
    }

    .dropzone {
      margin-top: 20px;
      width: 330px;
      max-width: 33%;
      height: 50px;
      border: 1px solid #000 !important;
      border-radius: 25px;
      background-color: transparent !important;
      padding-left: 20px;

      p {
        font-size: 15px;
        color: #000 !important;
      }

      .icon-downloading::before {
        right: 20px;
        color: #000 !important;
      }
    }

    .wrap-inputs {
      margin-top: 20px;
      gap: 20px;
      justify-content: space-between;
      display: grid;
      grid-template-columns: 1fr;

      &.col-3 {
        grid-template-columns: repeat(3, 1fr);
      }

      &.col-4 {
        grid-template-columns: repeat(4, 1fr);
      }

      .multi-select-dropdown {
        width: 100%;
        .dropdown-input {
          padding: 0 21px;
          width: 100%;
          font-family: "Figtree";
          border: 1px solid #000;
          min-height: 50px;
          height: fit-content;
          border-radius: 25px;
          .vo2-tag {
            display: none;
          }
          span {
            max-width: 100%;
            color: rgba(0, 0, 0, 0.5);
            white-space: initial;
            padding: 3px 0;
            text-transform: none;
            font-size: 10px;
            line-height: 16px;
            font-weight: 400;

            &.placeholder {
              color: #dadada;
            }
          }
        }
        .dropdown-menu {
          width: calc(100% - 10px);
          left: 5px;
          top: calc(100% + 5px);
          label {
            text-transform: none;
            font-family: "Figtree";
            font-weight: 400;
            padding: 6px 20px;
            margin-bottom: 0;
            &.selected {
              font-weight: 700;
            }
          }
        }
      }

      > div {
        display: flex;
        flex-direction: column;

        .tag-wrap {
          border-radius: 50px;
          border: 1px solid #000 !important;

          .tag-select__control {
            border: 0;
            border-radius: 50px;
            min-height: 48px;
            box-shadow: none;

            .tag-select__value-container {
              padding: 0 21px;
              font-family: "Figtree";
              align-items: center;
              box-sizing: border-box;
              .tag-select__multi-value {
                height: 20px;
              }
            }
          }
          &.multi-select {
            .tag-select__control {
              .tag-select__value-container {
                padding: 10px 21px;
              }
            }
          }
        }

        label {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
          margin-bottom: 12px;
          margin-left: 22px;
        }

        input {
          border: 1px solid #000 !important;
          font-size: 15px;
          font-family: "Figtree";
          padding: 6px 6px 6px 21px;
          &.field-input {
            height: 50px;
          }
        }
      }

      .image-wrap {
        // width: 240px;
        border-radius: 25px;
        background: rgba(199, 206, 209, 0.2);
        padding: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 0;

        .count {
          font-size: 12px;
          font-weight: 600;
          color: #000;
        }

        img {
          width: 100%;
          height: auto;
          max-height: 110px;
        }

        .name {
          font-size: 12px;
          color: #000;
          text-align: center;

          i {
            margin-left: 8px;

            &::before {
              font-size: 14px;
              color: #000;
              position: unset;
            }
          }
        }
      }
    }
  }
}

.wrapper {
  margin-top: 40px;
  margin-bottom: 80px;

  h2 {
    margin-bottom: 15px;
  }
}

.scheduler-wrapper {
  margin-top: 20px;
  padding: 30px !important;
  overflow: hidden;

  .rbc-header {
    padding-top: 3px;
  }

  .rbc-calendar {
    height: 750px !important;
  }

  .rbc-timeslot-group {
    min-height: 30px;
  }

  .styles-module_day-column__30McI .styles-module_title__2VBFp {
    font-size: 14px;
  }

  .rbc-allday-cell {
    display: none;
  }
  .rbc-time-view .rbc-header {
    border-bottom: none;
  }
}

.login {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-image: url(images/background-option-b.png);
  background-size: cover;
  background-position: center;

  .layer {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .top-header {
      // background-color: red;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
      padding: 75px;

      span {
        text-align: left;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 157px;
        color: white;
        line-height: 1;
      }

      img {
        width: 115px;
        height: auto;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      width: 380px;
      margin: auto;

      label {
        text-align: left;
        text-transform: uppercase;
        font-size: 12px;
        margin: 25px 0px 15px 20px;
        font-weight: 700;
        color: white;
      }

      input {
        background-color: transparent;
        color: white;
        height: 50px;
        font-size: 15px;
        letter-spacing: 1px;

        ::placeholder {
          color: white;
          opacity: 1; /* Firefox */
        }

        ::-ms-input-placeholder {
          /* Edge 12 -18 */
          color: white;
        }
      }

      input::placeholder {
        color: white;
        opacity: 0.6;
      }

      button {
        width: 139px;
        height: 50px;
        text-transform: uppercase;
        color: black;
        background-color: white;
        font-size: 16px;
        margin-top: 50px;
        margin: 50px auto 0px auto;
        font-family: "Jost";
        font-weight: 400;
      }
    }
  }
}

.device-info {
  &:after {
    clear: both;
    content: "";
    display: block;
  }

  .cell {
    float: left;
    width: 20%;
    padding: 20px 0;
    text-align: center;

    i {
      display: block;
      font-size: 20px;

      &:before {
        color: $mediumGreyText;
      }

      &.icon-pin {
        transform: rotate(180deg);
      }
    }

    span {
      display: block;
      color: $mediumGreyText;
      padding: 2px 0;
    }
  }
}

.rodal-container {
  .rodal-dialog {
    width: 510px !important;
    height: 302px !important;

    h3 {
      font-size: 26px;
    }
  }
}

.rodal-dialog {
  padding: 40px !important;
  height: fit-content !important;
  border-radius: 20px !important;
  h3 {
    font-size: 26px;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .remove-tag-notice {
    font-size: 16px;
    font-weight: normal;
    text-transform: initial;
    margin-top: 20px;
  }
  .tag-info {
    text-align: left;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    border-radius: 20px;
    border: 1px solid #dadada;
    margin-top: 40px;
    padding: 0 20px;
    box-sizing: border-box;
    font-family: "Figtree";
  }
  .modal-footer {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    button {
      width: 150px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      padding: 0;
      font-family: "Figtree";
      font-weight: 400;
    }
  }
}
.rodal {
  outline: none;

  .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 44px;
    background-color: #ededed;
  }

  .rodal-close {
    top: 14px;
    right: 14px;
    outline: none;
    height: 18px;
    width: 18px;

    &:before,
    &:after {
      background: #000000;
    }
  }

  .rodal-dialog {
    overflow: hidden;
    padding: 0;
  }

  .popup-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 24px;
    padding-top: 76px;

    h2 {
      margin-bottom: 32px;
    }

    .field {
      margin: 0;
      width: 100%;

      input {
        width: 100%;
        font-size: 16px;
      }
    }

    .actions {
      border-top: 0;
      margin: 48px auto 0;
      padding: 0;

      .blue {
        padding: 7px 30px;
      }

      .transparent {
        padding: 7px 24px;
      }
    }
  }

  .left {
    width: 510px;
    border-right: 1px solid #dadada;
  }

  .columns {
    height: 455px;
  }

  .right {
    width: 280px;

    h2 {
      margin-bottom: 24px;
    }
  }

  .left,
  .right {
    height: 100%;
    padding: 24px;
    float: left;
  }

  .tabs {
    position: relative;
    top: 0;
    margin-bottom: 24px;

    .tab {
      padding: 8px 12px;
      font-weight: 500;
      border-radius: 4px;
      cursor: pointer;
      color: #81838a;
      margin-left: 0;
      margin-right: 5px;

      &.active,
      &:hover {
        color: #0077d6;
        background: #d3eafd;
      }
    }
  }

  .rc-time-picker {
    width: 45%;
    margin-right: 5%;
  }

  h4 {
    text-transform: uppercase;
  }

  .field {
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .radio {
    padding: 0;

    .partial {
      display: inline-block;
      margin-left: 7px;
      background: #ededed;
      padding: 4px 12px;
      border-radius: 4px;
      margin-top: -4px;
    }

    input[type="text"] {
      padding: 2px 3px;
      width: 50px !important;
      text-align: center;
    }
  }

  .checkbox {
    display: block;
    margin-bottom: 4px;
    label {
      cursor: pointer;
      font-size: inherit;
      color: inherit;
      display: inline-block;
    }
  }

  .actions {
    border-top: 1px solid #dadada;
    text-align: center;
    padding: 23px;

    button {
      margin: 0 5px;
    }
  }
}

form.tags {
  .fields {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #dadada;
  }
}

form.tags,
form.projects {
  max-height: 100%;
  height: fit-content;

  .block {
    height: 100%;

    .scrollable {
      overflow: auto;
      height: 100%;
      padding-bottom: 40px;
    }
  }

  .flex-fields {
    display: flex;
    flex-direction: row;
  }

  .fields {
    display: flex;
    flex-direction: column;

    &.name {
      padding-bottom: 20px;

      .field {
        margin-top: 0px;

        input {
          height: 50px;
          border: 1px solid #000 !important;
          font-size: 15px;
          border-radius: 25px;
          font-family: "Figtree";
        }
      }
    }
  }

  .field {
    padding: 0;
    width: auto;
    min-height: 32px;
    width: 284px;

    & + .field {
      margin-top: 24px;
    }

    &:last-of-type {
      margin-top: 33px;
    }

    select {
      width: 85%;
    }
  }

  .image-wrap {
    display: flex;
    flex-direction: column;
    margin-right: 12px;

    &:nth-of-type(n + 5) {
      margin-top: 14px;
      margin-right: 14px;

      &.dropzone {
        margin-top: 24px;
      }
    }

    p {
      font-size: 12px;

      &.sub-title {
        opacity: 0.6;
        font-family: "Figtree";
        margin-bottom: 4px;
      }

      &.image-ext-name {
        font-family: "Inter";
      }
    }

    [class*="icon-"] {
      &:before {
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        color: #b2b9c6;
        font-size: 18px;
      }
    }

    .image-icon-wrap {
      display: flex;
      border: 1px solid #e6e7eb;
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      height: 32px;

      p {
        align-self: center;
        margin-left: 11px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 35px;
      }
    }

    img {
      width: 50px;
      height: 30px;
      object-fit: cover;
    }

    &.dropzone {
      background: rgba(33, 150, 243, 0.05);
      border: 1px dashed rgba(33, 150, 243, 0.5);
      cursor: pointer;
      justify-content: center;
      padding-left: 13px;
      margin-right: 12px;

      .image-icon-wrap {
        border-width: 0px;
        position: relative;

        p {
          color: #2196f3;
          font-family: "Figtree";
          margin: 0;
        }

        &[class*="icon-"] {
          &:before {
            color: #11ade4;
          }
        }
      }
    }
  }
}

.create-template-page {
  border-radius: 40px 40px 0 0;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .header-window {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #dadada;
    height: initial !important;
    button {
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      padding: 0 20px;
      background: #000;
      color: #fff;
      font-size: 12px;

      &.delete-button {
        background-color: #fff;
        border: 1px solid $red;
        color: $red;
      }
    }
  }
  .filters {
    margin: 20px 0;
  }
  .template-subtitle {
    font-family: "Jost";
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    color: #dadada;
    margin: 20px 0 0 0;
  }
  .template-field {
    margin-bottom: 20px;

    &.project-quantity {
      border-top: 1px solid #dadada;
    }

    input {
      padding: 16px 20px;
      width: 380px;
      font-family: "Figtree";
      font-size: 15px;

      &.quantity {
        border: none;
        background: rgba(199, 206, 209, 0.2);
      }
    }
  }
  .field-title {
    font-family: "Jost";
    font-size: 12px;
    font-weight: 700;
    padding: 0 20px;
    margin-bottom: 10px;
  }
  .note {
    font-family: "Figtree";
    font-size: 15px;
    font-weight: 500;
    margin: 0;
  }
  .table-wrapper {
    table {
      td {
        &:nth-of-type(1) {
          width: 25% !important;
          min-width: 200px;
        }
        &:nth-of-type(2) {
          width: 25% !important;
          min-width: 200px;
        }
        &:nth-of-type(3) {
          width: 25% !important;
          min-width: 240px;
        }
      }
    }
  }
}

.ant-space-item .vo2-download,
.vo2-editing {
  font-size: 16px;
}
