/** Colors **/
$grey: #1E183A;
$lightGrey: #C8C7DA;
$mediumGrey: #8F8FAC;
$mediumGreyText: #686868;
$tableHover: rgba(255, 238, 0, 0.025);

$blue: #2196F3;
$red: #FF4646;
$green: #17B714;
$greenButton: #2CDB5D;
$greyButton: #B5B7C8;
$orange: #F36D21;
