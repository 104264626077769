.multi-select-dropdown {
  position: relative;
  width: 220px;

  /* 输入框样式 */
  .dropdown-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    height: 30px;
    border-radius: 15px;
    cursor: pointer;
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid hsl(0, 0%, 80%);

    .value {
      display: flex;
      align-items: center;

      i {
        margin-right: 6px;
      }

      span {
        max-width: 152px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .icon-arrow-down {
      font-size: 18px;
      flex-shrink: 0;
    }
  }

  /* 下拉菜单 */
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-radius: 15px;
    box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 5px;
    z-index: 10;
    padding: 20px 0;
    font-family: "Figtree";

    label {
      padding: 5px 20px;
      cursor: pointer;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-left: 0 !important;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 400;

      &.selected {
        color: rgba(0, 0, 0, 1);
        font-weight: 700;

        span {
          background: #ffee00;
        }
      }

      input {
        visibility: hidden;
        width: 0;
        height: 0;
        margin: 0;
      }

      span {
        display: inline-block;
        min-width: 16px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #dadada;
        margin-right: 12px;

        // &.selected {
        //   background: #ffee00;
        // }
      }

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }

  .dropdown-menu::-webkit-scrollbar {
    display: none;
  }

  .dropdown-invisiable-layer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
  }
}
