/******
 * UI
 **/
body {
  font-family: "Jost";
  font-size: 14px;
}

a {
  color: #2196f3;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Jost";
  margin: 0;
  font-weight: 600;
}

h1 {
  font-size: 32px;
  color: #fff;
  line-height: 100%;
}

h2 {
  font-size: 24px;
  font-weight: 600;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.orange {
  color: $orange;
}

input,
select {
  background: #ffffff;
  border: 1px solid #e2e4e9;
  border-radius: 42px;
  padding: 6px 6px 6px 32px;
  outline: none;
  font-family: "Jost";
}

.field {
  label {
    font-size: 12px;
    color: #666;
    display: block;
    margin-bottom: 2px;
  }
  .radio {
    label {
      font-size: inherit;
      color: inherit;
      display: inline-block;
      cursor: pointer;
    }
  }
  input,
  select {
    border-radius: 2px;
    padding: 6px;
    font-size: 14px;
  }
}

.checkbox {
  overflow: hidden;
  position: relative;
  top: 1px;
  flex-shrink: 0;
  margin-right: 20px;

  input {
    position: absolute;
    top: -100px;
    height: 0;
    width: 0;
  }
  span {
    cursor: pointer;
    display: inline-block;
    margin-right: 3px;
    opacity: 0.5;
    // transition: 0.2s opacity;
    transition: 0.2s background-color;

    height: 16px;
    width: 16px;
    border-radius: 8px;
    background-color: #dadada;

    // &:before {
    // 	content: "\e907";
    // 	font-family: icomoon;
    // }
    &:hover {
      background-color: darken(#dadada, 25%);
      // opacity: 1;
    }
  }
  input:checked ~ span {
    background-color: #ffee00;
    // &:before {
    // 	content: "\e908";
    // }
    // opacity: 1;
  }
}

.radio {
  padding: 6px 0;
  input {
    height: auto !important;
    width: auto !important;
    display: inline-block;
  }
  span {
    display: inline-block;
  }
}

button,
.button {
  border-radius: 120px;
  padding: 7px 10px;
  background: $mediumGrey;
  border: none;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s;
  outline: none;
  text-transform: uppercase;
  font-family: "Figtree";

  &.red {
    background: $red;
    color: #fff;
    &:hover {
      background: darken($red, 10%);
    }
  }

  &.blue {
    background: $blue;
    color: #fff;
    &:hover {
      background: darken($blue, 10%);
    }
  }

  &.yellow {
    background: #ffee00;
    color: #000 !important;
    &:hover {
      background: darken(#ffee00, 10%) !important;
    }
  }

  &.transparent {
    background: transparent;
    border: 1px solid $blue;
    color: $blue;

    &:hover {
      background: rgba($blue, 0.05);
    }
  }

  &.green {
    background: $greenButton;
    color: #fff;
    &:hover {
      background: darken($greenButton, 10%);
    }
  }

  &.grey {
    background: $greyButton;
    color: #fff;
    &:hover {
      background: darken($greyButton, 10%);
    }
  }

  &.add {
    &:before {
      content: "\e924";
      font-family: icomoon;
      position: relative;
      margin-right: 5px;
      margin-left: 2px;
      top: 1px;
      font-size: 13px;
    }
  }

  &.black {
    background: #1a1b27;
    color: white;
    width: 124px !important;
  }
}

button {
  &.dropdown-btn {
    border: none;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    border-radius: 120px;
    font-size: 13px;

    .dropdown-icon {
      font-size: 11px;
    }
  }

  &:disabled {
    background: #dadada !important;
    cursor: not-allowed;
  }
}

.table-wrapper table {
  width: 100%;
  border-collapse: collapse;

  thead {
    font-family: "Jost";
    border-bottom: 1px solid #c7ced1;

    tr > td:not(:last-child)::after {
      content: "";
      // display: block;
      background-color: #c7ced1;
      height: 28px;
      width: 3px;
      position: absolute;
      right: 0;
      top: 13px;
    }

    td {
      position: relative;
      padding: 18px 20px;
      font-size: 14px;
      color: #1e183a;
      text-transform: uppercase;

      &.indent {
        padding-left: 57px;
      }

      &.hide-line::after {
        display: none;
      }

      i {
        &:before {
          font-size: 16px;
          position: relative;
          top: 2px;
        }

        &.icon-edit {
          &:before {
            color: #bcbac4;
            font-size: 20px;
          }
        }
      }

      &.sortable {
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: rgba(199, 206, 209, 0.1);
          border-radius: 4px;
        }

        &.active {
          background-color: rgba(199, 206, 209, 0.2);
        }
      }
    }
  }

  tbody {
    font-family: "Figtree";
    tr {
      cursor: pointer;
      border: 1px solid #e2e4e9;
      transition: 0.3s background-color;
      position: relative;

      &:first-child {
        border-top: none;
      }

      &:hover {
        background-color: $tableHover;
      }

      .bold {
        font-weight: 600;
      }

      &.selected {
        background-color: rgba(255, 238, 0, 0.1);
        .checkbox {
          span {
            background-color: #ffee00;
          }
        }
      }
    }
    td {
      padding: 11px 20px;

      &:nth-last-child(2) {
        width: 15%;
      }

      .tags {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .tag {
        width: fit-content;
        // background: rgba(20, 0, 55, 0.1);
        // border-radius: 4px;
        padding: 3px 6px;
        //margin-right: 4px;
        margin: 0px 4px 8px 0;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  td {
    &.left {
      text-align: left;
    }
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
    &.red {
      color: $red;
    }
    &.green {
      color: $green;
    }
    &.no-wrap {
      white-space: nowrap;
    }

    .icon-trash {
      opacity: 0.5;
      cursor: pointer;
      transition: opacity 0.25s;
      &:hover {
        opacity: 1;
      }
    }
  }

  &.draggable {
    table-layout: fixed;
    width: 100%;
    max-width: 1090px;

    tr {
      width: 100%;
    }
    td {
      &.dragging,
      &:nth-of-type(1) {
        width: 25% !important;
      }

      &.dragging,
      &:nth-of-type(2) {
        width: 25% !important;
      }

      &.dragging,
      &:nth-of-type(3) {
        width: 25% !important;
      }

      &.dragging,
      &:nth-of-type(4) {
        width: 100px !important;
      }

      &.dragging,
      &:nth-of-type(5) {
        width: 130px !important;
      }

      &.handler {
        width: 5%;
        span {
          background-image: url("./images/drag-image.png");
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.progress {
  background: rgba($blue, 0.1);
  color: $blue;
  border-radius: 3px;
  padding: 2px 5px 2px 22px;
  display: inline-block;
  position: relative;

  i {
    position: absolute;
    top: 3px;
    left: 5px;
    font-size: 15px;
  }

  &.orange {
    background: rgba($orange, 0.1);
    color: $orange;
  }

  &.red {
    background: rgba($red, 0.1);
    color: $red;
  }
}

.clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.empty {
  padding: 60px;
  text-align: center !important;
  color: $mediumGrey;
}

// CSS Loader
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    border: 4px solid red;
    opacity: 1;
    border-radius: 50%;
    animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}

// Audio player
.rhap_container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: none;
  padding: 15px 100px;
}

.push-player {
  height: 140px;
}

.icon-play {
  font-size: 20px;
  position: relative;
  top: 3px;
  margin-top: -8px;
  display: inline-block;
}

@keyframes loader {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
