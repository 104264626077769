/******
 * Fonts
 **/

@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/Jost/Jost-Regular.woff2") format("woff2"),
    url("fonts/Jost/Jost-Regular.woff") format("woff"),
    url("fonts/Jost/Jost-Regular.ttf") format("truetype"),
    url("fonts/Jost/Jost-Regular.otf") format("opentype"),
    url("fonts/Jost/Jost-Regular.eot") format("embedded-opentype"),
    url("fonts/Jost/Jost-Regular.svg") format("svg");
}

@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  src: url("fonts/Jost/Jost-Medium.woff2") format("woff2"),
    url("fonts/Jost/Jost-Medium.woff") format("woff"),
    url("fonts/Jost/Jost-Medium.ttf") format("truetype"),
    url("fonts/Jost/Jost-Medium.otf") format("opentype"),
    url("fonts/Jost/Jost-Medium.eot") format("embedded-opentype"),
    url("fonts/Jost/Jost-Medium.svg") format("svg");
}

@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  src: url("fonts/Jost/Jost-SemiBold.woff2") format("woff2"),
    url("fonts/Jost/Jost-SemiBold.woff") format("woff"),
    url("fonts/Jost/Jost-SemiBold.ttf") format("truetype"),
    url("fonts/Jost/Jost-SemiBold.otf") format("opentype"),
    url("fonts/Jost/Jost-SemiBold.eot") format("embedded-opentype"),
    url("fonts/Jost/Jost-SemiBold.svg") format("svg");
}

@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  src: url("fonts/Jost/Jost-Bold.woff2") format("woff2"),
    url("fonts/Jost/Jost-Bold.woff") format("woff"),
    url("fonts/Jost/Jost-Bold.ttf") format("truetype"),
    url("fonts/Jost/Jost-Bold.otf") format("opentype"),
    url("fonts/Jost/Jost-Bold.eot") format("embedded-opentype"),
    url("fonts/Jost/Jost-Bold.svg") format("svg");
}

@font-face {
  font-family: "Figtree";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/Figtree/Figtree-Regular.woff2") format("woff2"),
    url("fonts/Figtree/Figtree-Regular.woff") format("woff"),
    url("fonts/Figtree/Figtree-Regular.ttf") format("truetype"),
    url("fonts/Figtree/Figtree-Regular.otf") format("opentype"),
    url("fonts/Figtree/Figtree-Regular.eot") format("embedded-opentype"),
    url("fonts/Figtree/Figtree-Regular.svg") format("svg");
}

@font-face {
  font-family: "Figtree";
  font-style: normal;
  font-weight: 600;
  src: url("fonts/Figtree/Figtree-SemiBold.woff2") format("woff2"),
    url("fonts/Figtree/Figtree-SemiBold.woff") format("woff"),
    url("fonts/Figtree/Figtree-SemiBold.ttf") format("truetype"),
    url("fonts/Figtree/Figtree-SemiBold.otf") format("opentype"),
    url("fonts/Figtree/Figtree-SemiBold.eot") format("embedded-opentype"),
    url("fonts/Figtree/Figtree-SemiBold.svg") format("svg");
}

@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon/icomoon.eot?otl9xr");
  src: url("fonts/icomoon/icomoon.eot?otl9xr#iefix") format("embedded-opentype"),
    url("fonts/icomoon/icomoon.ttf?otl9xr") format("truetype"),
    url("fonts/icomoon/icomoon.woff?otl9xr") format("woff"),
    url("fonts/icomoon/icomoon.svg?otl9xr#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\e924";
  color: #fff;
}
.icon-sortby:before {
  content: "\e923";
  color: #8f8fac;
}
.icon-add-song:before {
  content: "\e900";
}
.icon-arrowback:before {
  content: "\e901";
  color: #8f8fac;
}
.icon-arrow-down:before {
  content: "\e902";
}
.icon-arrowright:before {
  content: "\e903";
}
.icon-arrow-up:before {
  content: "\e904";
}
.icon-bigsearch:before {
  content: "\e905";
}
.icon-block:before {
  content: "\e906";
}
.icon-checkbox-empty:before {
  content: "\e907";
}
.icon-checkbox-fill:before {
  content: "\e908";
  color: #bdbfd9;
}
.icon-checkbox-unselect:before {
  content: "\e909";
  color: #bdbfd9;
}
.icon-client:before {
  content: "\e90a";
  color: #72777a;
}
.icon-connectivity:before {
  content: "\e90b";
  color: #72777a;
}
.icon-device:before {
  content: "\e90c";
  color: #72777a;
}
.icon-device-1:before {
  content: "\e90d";
  color: #72777a;
}
.icon-done:before {
  content: "\e90e";
  color: #2196f3;
}
.icon-downloading:before {
  content: "\e90f";
  // color: #f36d21;
}
.icon-edit:before {
  content: "\e910";
  color: #3e3f54;
}
.icon-error:before {
  content: "\e911";
  color: #ff4646;
}
.icon-logo-vox:before {
  content: "\e912";
}
.icon-name:before {
  content: "\e913";
  color: #72777a;
}
.icon-next-button:before {
  content: "\e914";
}
.icon-pin:before {
  content: "\e915";
  color: #72777a;
}
.icon-play:before {
  content: "\e916";
  color: #2cdb5d;
}
.icon-playbutton:before {
  content: "\e917";
}
.icon-radio-buttonempty:before {
  content: "\e918";
}
.icon-radio-button-fill:before {
  content: "\e919";
}
.icon-settings-3:before {
  content: "\e91a";
}
.icon-sku:before {
  content: "\e91b";
  color: #72777a;
}
.icon-status:before {
  content: "\e91c";
  color: #72777a;
}
.icon-stop:before {
  content: "\e91d";
  color: #ff4646;
}
.icon-stop-button:before {
  content: "\e91e";
}
.icon-storage:before {
  content: "\e91f";
  color: #72777a;
}
.icon-timezone:before {
  content: "\e920";
  color: #72777a;
}
.icon-trash:before {
  content: "\e921";
}
.icon-validate-big:before {
  content: "\e922";
  color: #2cdb5d;
}

@font-face {
  font-family: "extra";
  src: url("fonts/extra/extra.eot?j2htlm");
  src: url("fonts/extra/extra.eot?j2htlm#iefix") format("embedded-opentype"),
    url("fonts/extra/extra.ttf?j2htlm") format("truetype"),
    url("fonts/extra/extra.woff?j2htlm") format("woff"),
    url("fonts/extra/extra.svg?j2htlm#extra") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="vo2-"],
[class*=" vo2-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "extra" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vo2-tag:before {
  content: "\e900";
}
.vo2-calendar:before {
  content: "\e901";
}
.vo2-download:before {
  content: "\e902";
}
.vo2-editing:before {
  content: "\e903";
}
